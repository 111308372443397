import React from 'react';

var GoIcon = function(id, classN, clickFn) {
    var ID = id ? id : "";
    var cln = classN ? classN : "";

    var clfn = null;
    if(clickFn) {
        clfn = function(evt) {
            clickFn();
            evt.stopPropagation();
            evt.preventDefault();
        }
    }
    
    return (
        <svg id={id} className={cln} onClick={clfn} x="0px" y="0px" viewBox="0 0 16 16">
        <g>
            <path d="M8,4.809V2.25c0-0.256-0.098-0.512-0.293-0.708C7.512,1.347,7.256,1.25,7,1.25   S6.488,1.347,6.293,1.542L0,7.75l6.293,6.207C6.488,14.152,6.744,14.25,7,14.25s0.512-0.098,0.707-0.293S8,13.505,8,13.25v-2.489   c2.75,0.068,5.755,0.566,8,3.989v-1C16,9.117,12.5,5.307,8,4.809z"/>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        </svg>
    );
}

export default GoIcon;