import React, {Fragment} from 'react';
import logo from './logo.svg';
import './App.css';
import profImage from './profImage.png';
import levaty from "./levaty.png";
import tca from "./tca.jpg";
import tcaTile2 from "./tcaTile2.png";
import roi from "./roi.png";
import aeTile2 from "./aeTile2.png";
import prferry from "./prferry.png";

import monj1 from "./monj1.png";
import monj2 from "./monj2.png";
import multipop from "./multipop.png";

import tca1 from "./tca1.png";
import tca2 from "./tca2.png";

import roi1 from "./roi1.png";
import roi2 from "./roi2.png";
import roi3 from "./roi3.png";

import ae1 from "./ae1.png";
import ae2 from "./ae2.png";
import ae3 from "./ae3.png";
import ae4 from "./ae4.png";
import ae5 from "./ae5.png";

import cm1 from "./cm1.png";
import cm2 from "./cm2.png";
import cm3 from "./cm3.png";

import prf1 from "./prf1.png";
import prf2 from "./prf2.png";

import shift1 from "./shift_eia_notes.png";
import shift2 from "./shift_twitter.png";
import shift3 from "./shift_login.png";

import GoIcon from './GoIcon';

window.onbeforeunload = function() {
  window.scrollTo(0,0);
};

class App extends React.Component {
  constructor(props) {
    // Required step: always call the parent class' constructor
    super(props);
    // Set the state directly. Use props if necessary.
    this.state = {
      levatyImg: monj1,
      tcaImg: null,
      roiImg: null,
      aeImg: null,
      prfImg: null,
    }
  }
  tileClick(id) {
    console.log(id);
    var el = document.getElementById(id);
    el.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }
  toProject(url) {
    window.open(url, "_blank");
  }
  render() {
    return(
      <div className="App">
        {/* <header className="App-header">
          <div className="header-item">About</div>
          <div className="header-item">Work</div>
        </header> */}
        <div className="about-section">
          <div className="about-left">
            <div className="about-title first">
              <img src={profImage} className="profile-image"/>
              <div className="name">Upamanyu Sundaram</div>
              <div className="contact">
                <a href="mailto:upabausun@gmail.com">upasun1@gmail.com</a>
                <a href="tel:+16262412286">(626)-241-2286</a>
              </div>
            </div>
            <div className="about-subtitle">
              Analyze. Develop. Design.
            </div>
            {/* <div className="about-text">
              Three concepts which existed before any notion of material design, programming, or statistics. Three tenants of
              technology that have accelerated our growth beyond expectations, and will continue to do so. This is what inspires my curiosity. A belief that science and technology are ingrained into humanity’s
              evolution, and that in understanding them we are provided a lens into the inner workings
              of not only the world around us, but also ourselves.
            </div> */}
            <div className="about-text">
              I would like to learn about ways to leverage the data at hand toward understanding our environment and devloping solutions for real-world challenges. Using such analyses my goal is to create beautiful, enjoyable, and valuable experiences. My top fields of interest are Machine Learning, Data Analysis & Visualization, and User Experience.
            </div>
            <div className="about-text">
              Get in touch if you'd like to learn more about me or my projects!
            </div>
          </div>
          <div className="about-right">
            <div className="about-title">
              Projects
            </div>
            <div className="tiles-container">
              <div onClick={this.tileClick.bind(this, "prf")} data-tag="prf" className="tile"><div className="tic"><img src={prferry} className="tile-image"></img></div><div className="tile-text">PR Ferry</div></div>
              <div onClick={this.tileClick.bind(this, "ae")} data-tag="ae" className="tile"><div className="tic"><img src={aeTile2} className="tile-image"></img></div><div className="tile-text">Advice Engine</div></div>
              <div onClick={this.tileClick.bind(this, "roi")} data-tag="roi" className="tile"><div className="tic"><img src={roi} className="tile-image"></img></div><div className="tile-text">My MC Roi</div></div>
              <div onClick={this.tileClick.bind(this, "shift")} data-tag="tca" className="tile"><div className="tic"><img src={shift3} className="tile-image"></img></div><div className="tile-text">SHIFT - Vesica Tech.</div></div>
              <div onClick={this.tileClick.bind(this, "tca")} data-tag="tca" className="tile"><div className="tic"><img src={tcaTile2} className="tile-image"></img></div><div className="tile-text">The Total Cost Analysis</div></div>
              <div onClick={this.tileClick.bind(this, "levaty")} data-tag="levaty" className="tile"><div className="tic"><img src={levaty} className="tile-image"></img></div><div className="tile-text">Levaty</div></div>
            </div>
          </div>
          <div id="prf" className="about-project flip">
            <div className="project-right">
              <div className="project-title has-go prf">PR Ferry {GoIcon(null,"go-icon", this.toProject.bind(this,"http://viequesferry.org"))}</div>
              <div className="project-role">Generation Gives – Volunteer</div>
              <div className="project-title sub prf">PR Ferry</div>
              <div className="project-text">PR Ferry is a volunteer driven effort seeking to reduce the uncertainty around ferry journeys for trips to and from Ceiba, Vieques, and Culebra. The application receives updates approximately every two minutes, which are then used to track and display the ships as well as provide an ETA with ~85% confidence. Users may subscribe to alerts notifying them of arrivals, departures, and corrections regarding a specific port or ship.</div>
              <div className="project-title sub two prf">Role</div>
              <div className="project-list">
                <div className="list-head">Designed the app as a serverless application on AWS from the ground up. Notable tasks include - </div>
                <ul>
                  <li>Configuring a TensorFlow CNN model to recognize when a ship is off of its expected course and properly reset it</li>
                  <li>Setting up AWS RDS with MySQL to store and properly shape data using triggers and stored procedures</li>
                  <li>Connecting the client to RDS through AWS API Gateway and Lambda</li>
                  <li>Hosting a domain using Route 53 and an S3 bucket</li>
                  <li>Configuring a VPC and NAT Gateway to allow Lambdas to communicate with both RDS and other internet services from within the virtual private cloud</li>
                  <li>Configuring AWS SNS to send texts to those subscribed to receive notifications</li>
                </ul>
                <div className="list-head mtop">Continued work includes - </div>
                <ul>              
                  <li>Refine subscriptions to allow for certain timeframes within a recurring timeframe (e.g. weekly on Mon - Thurs 11AM - 4PM)</li>
                  <li>Tune CNN Model to account for starting port differences to offer a better lead time in the case of a different expected destination</li>
                </ul>
              </div>
              <div className="project-title sub two prf">Technologies</div>
              <div className="project-text">React, Less, TensorFlow.js, AWS - Lambda/S3/API Gateway/RDS</div>
            </div>
            <div className="project-images">
              <img className="displayed-image" src={prf1}></img>
              <img className="displayed-image" src={prf2}></img>
            </div>
          </div>
          <div id="ae" className="about-project">
            <div className="project-right">
              <div className="project-title has-go ae">Advice Engine {GoIcon(null,"go-icon", this.toProject.bind(this,"https://edge.mortgagecoach.com/mc-editor"))}</div>
              <div className="project-role">MortgageCoach</div>
              <div className="project-title sub ae">MC AE</div>
              <div className="project-text">A port and rebranding of MortgageCoach’s Edge application from Flash to HTML5 with the addition of new features to improve usability, tracking, and training. The Advice Engine enables Loan Officers (LOs) to create Total Cost Analysis presentations for potential borrowers. Beyond its services for individual LOs, it enables MortgageCoach enterprise clients to “Automate, Integrate, and Educate” from within the platform. This allows for the Advice Engine to plug into almost every aspect of the loan origination pipeline, giving MortgageCoach access to valuable data off which innovations can be strategized to both increase the size of existing accounts and bring in new accounts.</div>
              <div className="project-list">
                <div className="list-head">Notable features include - </div>
                <ul>
                  <li>¾ responsive design – not focusing on mobile because we have dedicated iOS and Android apps</li>
                  <li>Targeted content by different user levels</li>
                  <li>A Content Manager section to allow for “super admins” to create and publish targeted content from within the Advice Engine.</li>
                  <li>Session tracking, detailed error tracking, content engagement tracking</li>
                  <li>Strategy Templates for easy creations of multi-scenario presentations</li>
                  <li>Integrations with Encompass, Optimal Blue, and Closing Corp to bring in closed loan data, pricing data, and itemized fee data to automate much of the creation process</li>
                  <li>Easy access to presentations at any time during creation</li>
                  <li>Tooltips explaining almost every field, and a collapsible toolbar</li>
                </ul>
              </div>
              <div className="project-title sub two ae">Role</div>
              <div className="project-list">
                <div className="list-head">Responsibilities included - </div>
                <ul>
                  <li>Leading design by rapid implementation followed by iterations on the UX. A developed template decided by myself from a general UI and dev perspective and by our Director of Technology from a Loan Officer and application specific perspective would be presented and in-house graphics would then polish the template to give our final design</li>
                  <li>Developing the frontend, taking into consideration reusability of components in subsequent applications</li>
                  <li>Developing the web services along with a co-worker to improve TTFB timings by identifying and avoiding expensive database operations triggered by service requests</li>
                  <li>Analyzing usage and providing a breakdown by timeframe, users, devices, and organizations</li>
                  <li>Designing the Content Manager system and breaking down the design into required tables, services, view, and other required resources such as streaming via AWS MediaConvert and S3</li>
                  <li>Designing email and survey campaigns based off of usage analysis addressing errors, new features, and soliciting feedback</li>
                </ul>
                <div className="list-head mtop">Continued work includes - </div>
                <ul>
                  <li>Gamification to improve engagement and provides means for gathering more data</li>
                  <li>Integration with MC Automation application to allow transforming batched closed loan imports into TCA presentations</li>
                </ul>
              </div>
              <div className="project-title sub two ae">Technologies</div>
              <div className="project-text">React, d3.js, Less, .NET, Sql Server, AWS</div>
            </div>
            <div className="project-images">
              <img className="displayed-image" src={ae1}></img>
              <img className="displayed-image" src={ae2}></img>
              <img className="displayed-image" src={ae3}></img>
              <img className="displayed-image" src={ae4}></img>
              <img className="displayed-image" src={ae5}></img>
              <img className="displayed-image" src={cm3}></img>
              <img className="displayed-image" src={cm2}></img>
              <img className="displayed-image" src={cm1}></img>
            </div>
          </div>
          <div id="roi" className="about-project flip">
            <div className="project-right">
              <div className="project-title has-go roi">My MC ROI {GoIcon(null,"go-icon", this.toProject.bind(this,"https://edge.mortgagecoach.com/roi"))}</div>
              <div className="project-role">MortgageCoach</div>
              <div className="project-title sub roi">MC ROI Calculator</div>
              <div className="project-text">A MortgageCoach sales tool designed to visualize the ROI gained by potential customers at all membership levels. It uses a MortgageCoach case studied model of locality based Unfundable Loan Rates and Opportunity Rates to predict additional potential closed loan volume as a result of purchasing a MortgageCoach subscription.</div>
              <div className="project-title sub two roi">Role</div>
              <div className="project-text">Led the design and development of this project by translating the business requirements as outlined by sales and marketing into technical requirements for implementation. Final design is a well-polished refinement of the initial layout by our in-house designer. Currently maintain application.</div>
              <div className="project-list">
                <div className="list-head">Needs were broken down as follows - </div>
                <ul>
                  <li>A UI exposing a set of provided filters</li>
                  <li>Which upon modification perform calculations and update an “ROI Sheet”</li>
                  <li>Which can then be shared with a potential client</li>
                  <li>Along with explanations of purpose of calculator and terms</li>
                </ul>
                <div className="list-head mtop">These needs were translated into – </div>
                <ul>
                  <li>A fully responsive web page most prominently featuring the potential ROI alongside the potential additional compensation amount</li>
                  <li>Visual breakdowns of potential opportunity values, and of potential total values with MortgageCoach</li>
                  <li>An organized set of “Assumptions” form filters, ands sets of calculated “Current”, “Loss”, and “Return” figures</li>
                  <li>Other features including – save, print, social share, video annotation, and About and Glossary tabs</li>
                </ul>
              </div>
              <div className="project-title sub two roi">Technologies</div>
              <div className="project-text">React, d3.js, Less, S3, Elastic-Transcoder, Lambda</div>
            </div>
            <div className="project-images">
              <img className="displayed-image" src={roi2}></img>
              <img className="displayed-image" src={roi1}></img>
              <img className="displayed-image" src={roi3}></img>
            </div>
          </div>
          <div id="shift" className="about-project">
            <div className="project-right">
              <div className="project-title has-go shift">SHIFT {GoIcon(null,"go-icon", this.toProject.bind(this,"https://app.shifttech.com"))}</div>
              <div className="project-role">Vesica Technologies</div>
              <div className="project-title sub shift">SHIFT</div>
              <div className="project-text">SHIFT is an up and coming trading platform that offers insight and convenience to traders by moving beyond machine codes toward an intuitive search interface to easily visualize the market color in commodities markets around the globe.</div>
              <div className="project-text">Distinguishing features include widgets that display the biggest and latest trades for a commodity, widgets to show volitility, widgets that contextualize trades with public production / consumption / imports / and exports of a commodity, and widgets to display realtime reactions from relevant users about commodities and pertinent events via Twitter.</div>
              <div className="project-title sub two shift">Role</div>
              <div className="project-text">Hired as a full-stack consultant to assist with the rapid innovation required of a start-up and did so by designing and developing, across the backend, middleware, and frontend, an EIA integration and Twitter integration.</div>
              <div className="project-list">
                <div className="list-head">Notable Accomplishments - </div>
                <ul>
                  <li>Integrated the Energy Information Administration's commodities data with Chicago Market Exchange's trade data in graphical format.</li>
                  <li>Provided context about ongoing and historical trades by creating a Twitter integration identifying tweets by credible sources about relevant commodities.</li>
                  <li>Preserved the in-app experience by adding a Notes widget to allow users to jot down important notes without leaving the view.</li>
                  <li>Attracted potential customers by executing email marketing campaigns through AWS SES.</li>
                  <li>Refined the user experience by modifying styles to conform to modern UI expectations.</li>
                </ul>
              </div>
              <div className="project-title sub two shift">Technologies</div>
              <div className="project-text">React, React Redux, Highcharts, Less, SES, node.js, MySQL</div>
            </div>
            <div className="project-images">
              <img className="displayed-image" src={shift1}></img>
              <img className="displayed-image" src={shift2}></img>
              <img className="displayed-image" src={shift3}></img>
            </div>
          </div>
          <div id="tca" className="about-project flip">
            <div className="project-right">
              <div className="project-title has-go tca">The Total Cost Analysis {GoIcon(null,"go-icon", this.toProject.bind(this,"https://edge.mortgagecoach.com/report/edgereport.html#295d4bb3-e144-4420-8912-8fa025ab80d4"))}</div>
              <div className="project-role">MortgageCoach</div>
              <div className="project-title sub tca">TCA</div>
              <div className="project-text">The MortgageCoach TCA is the most popular loan presentation software used by the top 10% of loan officers in the United States. It provides borrowers insights beyond simply their APR, down payment, and closing costs into the long term financial implications of making, what is for most, the biggest purchase of their lives.</div>
              <div className="project-text">Prominent features include an integration with Social Survey, social share, click to call, video annotation, live highlighting, and reinvestment strategies.</div>
              <div className="project-title sub two tca">Role</div>
              <div className="project-text">Coordinated the transformation of a Flash web app into a modern HTML5 application. Responsible for the entirety of the frontend, for coordinating the development of web services, and subsequent roll outs to beta and production. Aided in design through identifying best practices and implementing A/B testing. Developed procedures to track and analyze integration usage, providing weekly reports on activity broken down along different parameters such as by presentation/office/organization etc. Currently maintain the application as a part of my duties.</div>
              <div className="project-title sub two tca">Technologies</div>
              <div className="project-text">jQuery, Less, Sql Server, S3, Elastic-Transcoder, Lambda</div>
            </div>
            <div className="project-images">
              <img className="displayed-image" src={tca2}></img>
              <img className="displayed-image" src={tca1}></img>
            </div>
          </div>
          <div id="levaty" className="about-project">
            <div className="project-right">
              <div className="project-title has-go levaty">Levaty {GoIcon(null,"go-icon", this.toProject.bind(this,"https://monj.com"))}</div>
              <div className="project-role">Frontend Contractor – 6 Months</div>
              <div className="project-subtitle">My time at Leavty consisted of working as a frontend contractor for two SaaS startups - Monj and Multipop (hence the many colors!)</div>
              <div className="project-title sub monj">Monj</div>
              <div className="project-text">A food and nutrition education application. Built with a focus around responsive design and a customer first experience.</div>
              <div className="project-title sub two monj">Role</div>
              <div className="project-text">One of two developers responsible for collaborating with the designers and directors of Monj to bring their website to life.</div>
              <div className="project-title sub two monj">Technologies</div>
              <div className="project-text">Angular, Bootstrap, Less, REST api</div>
              <div className="project-title sub multipop">Multipop</div>
              <div className="project-text">Brightcove widget for embedded shopping experience providing convenience for the buyer and visibility for the seller.</div>
              <div className="project-title sub two multipop">Role</div>
              <div className="project-text">Tasked with building out the basic components required to display specific interactive ads at certain times in videos embedded with the Multipop widget. Ultimately, the goal was to provide a convenient method to purchase goods relevant to what was displayed.</div>
              <div className="project-title sub two multipop">Technologies</div>
              <div className="project-text">jQuery, d3.js, Less, Google Ad Sense, HSN ads</div>
            </div>
            <div className="project-images">
              <img className="displayed-image" src={monj1}></img>
              <img className="displayed-image" src={monj2}></img>
              <img className="displayed-image" src={multipop}></img>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
